import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import PreviewRow from '../components/blog/PreviewRow.js'
import CaseStudyPreviewRow from '../components/casestudies/PreviewRow.js'
import Faq from '../components/common/FAQ.js'
import Header from '../components/common/Header'
import AppCTA from '../components/cta/AppCTA'
import DownloadCTA from '../components/cta/DownloadCTA.js'
import GeneralCTA from '../components/cta/GeneralCTA.js'
import NewsletterCTA from '../components/cta/NewsletterCTA.js'
import Layout from '../components/layout'
import Seo from '../components/seo'
import LatestWebinar from '../components/webinar/LatestWebinar.js'

class RootIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.nodes')

    // sort blog posts with oldest last
    const orderedPosts = posts.sort((a, b) => {
      return new Date(b.rawDate) - new Date(a.rawDate)
    })

    return (
      <Layout location={this.props.location}>
        <Seo
          title="Knowledge hub | Industry best practice for electric mobility | Urban Fox"
          description="Blog posts, webinars, case studies and more to help you stay up to date with the latest in electric vehicle charging technology."
        />
        <Header filter="Knowledge Hub" />
        <PreviewRow
          posts={orderedPosts}
          showButton
          title="Read our recent blog posts"
        />
        <DownloadCTA />
        <LatestWebinar />
        <Faq filter="Knowledge Hub" />
        <CaseStudyPreviewRow
          title="Trusted by local authorities across the UK"
          showButton
        />
        <NewsletterCTA />
        <AppCTA />
        <GeneralCTA />
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      limit: 3
    ) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        tags
        author {
          name
          heroImage: image {
            gatsbyImage(layout: CONSTRAINED, placeholder: BLURRED, width: 1180)
          }
        }
        rawDate: publishDate
        heroImage {
          gatsbyImage(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        body {
          raw
        }
        description {
          raw
        }
      }
    }
  }
`
