import { graphql, useStaticQuery } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import Button from '../common/Button'
import Icon from '../common/Icon'
import PreviewItem from './PreviewItem'

const PreviewRow = ({ showButton = false, title = 'Case Studies' }) => {
  const data = useStaticQuery(graphql`
    query allContentfulCaseStudyQuery {
      allContentfulCaseStudy(
        sort: { fields: [publishDate], order: DESC }
        limit: 3
      ) {
        nodes {
          title
          slug
          localAuthority
          publishDate(formatString: "MMMM Do, YYYY")
          rawDate: publishDate
          heroImage {
            gatsbyImage(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
          body {
            raw
          }
          description {
            raw
          }
        }
      }
    }
  `)

  const caseStudies = get(data, 'allContentfulCaseStudy.nodes', null)

  if (!caseStudies) {
    return (
      <div className="w-full max-w-7xl mx-auto px-6">
        <h3 className="text-3xl font-bold text-center text-gray-900 py-16">
          No case studies available.
        </h3>
        <Icon
          name="NewspaperIcon"
          className="w-full h-24 mb-24 text-uforange-600"
        />
      </div>
    )
  }

  return (
    <div className="relative bg-white px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
      <h3 className="text-3xl font-bold text-center text-gray-900 pb-8">
        {title}
      </h3>
      <div className="relative mx-auto max-w-7xl">
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          {caseStudies.map((study, index) => (
            <PreviewItem item={study} key={index} />
          ))}
        </div>
      </div>
      {showButton && (
        <div className="w-full mt-12 text-center items-center">
          <Button label="Read more" href="/case-studies" />
        </div>
      )}
    </div>
  )
}

export default PreviewRow
